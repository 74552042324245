<template>
  <section>
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
              Modifier client
              <router-link to="/getclients">
                <span class="float-right btn btn-info"> retour </span>
              </router-link>
            </div>
            <div class="container">
                          <div class="row">
      
          <div class="row">
       <GmapAutocomplete class="form-control" @place_changed="setPlace" />
        <button  style=" margin-left: 10px;
  padding: 10px 20px;
  background-color: rgb(158, 228, 215);" class="btn" @click="addMarker">Modifier</button>
     </div>
   

    <GmapMap
      ref="map"
      :center="center"
      :zoom="zoom"
      style="width: 100%; height: 400px; margin-top: 20px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap>

    </div>
    <br>
    <br>
               <form class="forms-sample" @submit.prevent="updateClient()">
            <div class="form-group">
              <label for="exampleInputName1"
                >Nom<span class="obligatoire">*</span></label
              >
              <input
                v-model="name"
                type="text"
                class="form-control"
                id="nom"
                placeholder="nom"
                required
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail3"
                >Adresse<span class="obligatoire">*</span></label
              >
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="email"
                placeholder="email"
                required
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword4"
                >Telephone<span class="obligatoire">*</span></label
              >
              <input
                v-model="phone"
                
                class="form-control"
                id="phone"
                placeholder="phone"
                required
              />
            </div>
            
                <div class="row">

                <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >RNE<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="rne"
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="rne"
                        required
                      />
                    </div>
                     
                  </div>
                   <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >Reference<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="reference"
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="Reference"
                        required
                      />
                    </div>
                     </div>
                      <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword4"
                        >Patente<span class="obligatoire">*</span></label
                      >
                      <input
                        v-model="patente"
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="Patente"
                        required
                      />
                    </div>
                    </div>
                    </div>
            <!-- <div class="form-group">
              <label for="last"
                >Catégorie <span class="obligatoire">*</span></label
              >
              <select
                class="form-control"
                id="categorie"
                v-model="categorie"
                required
              >
                <option value="Hopital">Hopital</option>
                <option value="Medecin">Medecin</option>
                <option value="Pharmacie">Pharmacie</option>
              </select>
            </div> -->
                
            <div class="form-group">
              <label for="exampleInputPassword4"
                >Catégorie<span class="obligatoire">*</span></label
              >
              <input
               v-model="categorie"
                type="text"
                class="form-control"
                id="adresse"
                placeholder="adresse"
                required
              />
            </div>

            <div class="form-group">
              <label for="exampleInputPassword4"
                >Adresse<span class="obligatoire">*</span></label
              >
              <input
                v-model="adresse"
                type="text"
                class="form-control"
                id="adresse"
                placeholder="adresse"
                required
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail3">Latitude</label>
              <input
                v-model="latitude"
                type="text"
                class="form-control"
                id="latitude"
                placeholder="latitude"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword4">Longitude</label>
              <input
                v-model="longitude"
                type="text"
                class="form-control"
                id="longitude"
                placeholder="longitude"
              />
            </div>
            
                    <div v-show="categorie=='Particulier'" class="form-group">
                      <label for="exampleInputCity1"
                        >Registre de commerce<span class="obligatoire"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="registre"
                        v-model="registre"
                        placeholder="registre"
                      />
                    </div>
                

                 
                    <div v-show="categorie=='Particulier'" class="form-group">
                      <label for="exampleSelectGender"
                        >Mode Exercice<span class="obligatoire">*</span></label
                      >
                      <select
                        class="form-control"
                        id="mode_exercice"
                        v-model="mode_exercice"
                      >
                        <option>Médecin santé publique</option>
                        <option>Médecin libre pratique</option>
                        <option>Médecin hospitalo universitaire</option>
                      </select>
                    </div>
                

                 
                    <div v-show="categorie=='Particulier'" class="form-group">
                      <label for="exampleSelectGender"
                        >Specialité<span class="obligatoire">*</span></label
                      >
                      <select class="form-control" v-model="specialite">
                        <option>Anatomie</option>
                        <option>Anatomie et cytologie pathologique</option>
                        <option>Anesthésie réanimation</option>
                        <option>Biologie médicale</option>
                        <option>Biologie médicale option biochimie</option>
                        <option>Biologie médicale option hématologie</option>
                        <option>Biologie médicale option immunologie</option>
                        <option>Biologie médicale option microbiologie</option>
                        <option>Biologie médicale option parasitologie</option>
                        <option>Biophysique et médecine nucléaire</option>
                        <option>Carcinologie médicale</option>
                        <option>Cardiologie</option>
                        <option>Chirurgie carcinologique</option>
                        <option>Chirurgie cardio vasculaire</option>
                        <option>Chirurgie générale</option>
                        <option>Chirurgie neurologique</option>
                        <option>
                          Chirurgie orthopédique et traumatologique
                        </option>
                        <option>Chirurgie pédiatrique</option>
                        <option>
                          Chirurgie plastique réparatrice et esthétique
                        </option>
                        <option>Chirurgie thoracique</option>
                        <option>Chirurgie urologique</option>
                        <option>Chirurgie vasculaire périphérique</option>
                        <option>Dermatologie</option>
                        <option>
                          Direction Et Logistique Médico-militaire
                        </option>
                        <option>Endocrinologie</option>
                        <option>Gastro-entérologie</option>
                        <option>Génétique</option>
                        <option>Gynécologie obstétrique</option>
                        <option>Hématologie clinique</option>
                        <option>Histo-embryologie</option>
                        <option>Hygiène Nucléaire</option>
                        <option>Imagerie médicale</option>
                        <option>Maladies infectieuses</option>
                        <option>Médecine aéronautique et spatiale</option>
                        <option>Médecine de Famille</option>
                        <option>Médecine De La Plongée Sous-marine</option>
                        <option>Médecine du travail</option>
                        <option>Médecine d'urgence</option>
                        <option>Médecine générale</option>
                        <option>Médecine interne</option>
                        <option>Médecine légale</option>
                        <option>Médecine nucléaire</option>
                        <option>
                          Médecine physique, rééducation et réadaptation
                          fonctionnelle
                        </option>
                        <option>Médecine préventive et communautaire</option>
                        <option>Néphrologie</option>
                        <option>Neurologie</option>
                        <option>Nutrition et maladies nutritionnelles</option>
                        <option>Ophtalmologie</option>
                        <option>Oto-rhino-laryngologie</option>
                        <option>Pédiatrie</option>
                        <option>Pédo psychiatrie</option>
                        <option>Pharmacologie</option>
                        <option>
                          Physiologie et exploration fonctionnelle
                        </option>
                        <option>Pneumologie</option>
                        <option>Psychiatrie</option>
                        <option>Radiothérapie carcinologique</option>
                        <option>Réanimation médicale</option>
                        <option>Rhumatologie</option>
                        <option>sans spécialité</option>
                        <option>Spécialiste en médecine de famille</option>
                        <option>
                          Stomatologie et chirurgie maxillo-faciale
                        </option>
                        <option>Urologie</option>
                      </select>
                    </div>
                 

                    <div class="form-group" v-show="categorie=='Entreprise'">
                     
                      <label for="exampleInputCity1"
                        >Matricule Fiscale<span class="obligatoire"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="matricule"
                        placeholder="matricule fiscale"
                      />
                    </div>
                  

                
                    <div class="form-group">
                      <label for="exampleInputCity1"
                        >Délégation<span class="obligatoire">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="delegation"
                        placeholder="delegation"
                      />
                    </div>
                
                 
                    <div class="form-group">
                      <label for="exampleSelectGender"
                        >Gouvernerat<span class="obligatoire">*</span></label
                      >
                      <select class="form-control" v-model="gouvernerat">
                        <option>Ariana</option>
                        <option>Béja</option>
                        <option>Ben Arous</option>
                        <option>Bizerte</option>
                        <option>Gabès</option>
                        <option>Gafsa</option>
                        <option>Jendouba</option>
                        <option>Kairouan</option>
                        <option>Kasserine</option>
                        <option>Kébili</option>
                        <option>Le Kef</option>
                        <option>Mahdia</option>
                        <option>La Manouba</option>
                        <option>Médenine</option>
                        <option>Monastir</option>
                        <option>Nabeul</option>
                        <option>Sfax</option>
                        <option>Sidi Bouzid</option>
                        <option>Siliana</option>
                        <option>Sousse</option>
                        <option>Tataouine</option>
                        <option>Tozeur</option>
                        <option>Tunis</option>
                        <option>Zaghouane</option>
                      </select>
                    </div>
             

            <button type="submit" class="btn btn-gradient-primary mr-2">
              Modifier
            </button>
            <router-link :to="{ name: 'clients' }"
              ><button class="btn btn-light">Anuuler</button></router-link
            >
          </form>
            </div>
         
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import axios
import { HTTP } from '@/axios';

export default {
  data() {
    return {
       zoom:9,
      center: { lat: 36.794895, lng: 10.170715 },
      currentPlace: null,
      markers: [],
      name: "",
      adresse: "",
      phone: "",
      categorie: "",
      email: "",
      latitude: "",
      longitude: "",
      matricule:"",
       registre: "",
      gouvernerat: "",
       specialite: "",
      mode_exercice: "",
      delegation: "",
        patente:"",
      rne:"",
      reference:"",
    };
  },
     mounted() {
    // this.locateGeoLocation();
    $('.pac-target').attr('placeholder','Some New Text 1');
  },
  created() {
    this.getClientById();
  },
  methods: {
      setPlace(place) {
      this.currentPlace = place;
    },
       addMarker() {
      if (this.currentPlace) {
          this.markers=[],
         this.name=this.currentPlace.name
         this.adresse=this.currentPlace.formatted_address
         this.phone=this.currentPlace.formatted_phone_number
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
      
        this.markers.push({ position: marker });
        
        this.center = marker;
        
          this.zoom=15;
        this.currentPlace = null;
         this.longitude= marker.lng;
         this.latitude=marker.lat;
         
      }
    },
      locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        };
      });
    },
    getClientById() {
      HTTP
        .get("clients/getclientsbyid/" + this.$route.params.id)
        .then((response) => {
          this.name = response.data.name;
          this.adresse = response.data.adresse;
          this.phone = response.data.phone;
          this.categorie = response.data.categorie;
          this.email = response.data.email;
          this.latitude = response.data.latitude;
          this.longitude = response.data.longitude;
          this.matricule = response.data.matricule;
          this.gouvernerat = response.data.gouvernerat;
          this.delegation = response.data.delegation;
          this.specialite = response.data.specialite;
          this.mode_exercice = response.data.mode_exercice;
          this.registre = response.data.registre;
            this.rne= response.data.rne,
          this.patente= response.data.patente,
         this.reference= response.data.reference

              const marker = {
          lat: Number(this.latitude),
          lng: Number(this.longitude),
        };
      
        this.markers.push({ position: marker });
          this.center = {
           lat: Number(this.latitude),
          lng: Number(this.longitude),
        };

        })

        .then((err) => console.log(err));
      console.log(this.response.categorie);
    },
    updateClient() {

      let client = {
        name: this.name,
        adresse: this.adresse,
        phone: this.phone,
        categorie: this.categorie,
        email: this.email,
        latitude: this.latitude,
        longitude: this.longitude,
        gouvernerat : this.gouvernerat,
        delegation :this.delegation,
        specialite :this.specialite,
         mode_exercice :this.mode_exercice,
         registre : this.registre,
        _id: this.$route.params.id,
          rne: this.rne,
          patente: this.patente,
         reference: this.reference
      };


      HTTP.put("clients/updateclient/", client).then(
        (response) => {
          if (response.status === 200) {
            Toast.fire({
              icon: "success",
              title: "client modifié",
            });

            this.$router.push("/getclients");
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
};
</script>
<style>
.vue-map-container,
.vue-map-container .vue-map {
    width: 100%;
    height: 100%;
}

.pac-target-input {
 
  width: 20%;
   content : "some other placeholder very long" ;
   
  font-size: 1em;
  font-style: italic;

}

.btn {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: rgb(158, 228, 215);
} 

</style>
